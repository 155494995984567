html {
    overflow-x: hidden;
}
body {
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  /*background: #F2DFBB;*/
  background: linear-gradient(90deg,#1f1e1f,#4e0a4e,#1f171f);
  background-size: 100% 100%;
  /*animation: gradient-animation 24s ease infinite;*/
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
