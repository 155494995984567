@font-face {
        font-family: 'Roboto-Light';
        src: url('./fonts/Roboto-Light.ttf') format("truetype");
}

.Input {
    margin-bottom: 15px;
}

.Input label {
    font-weight: 600;
    color: #D96704;
    display: block;
}

.Input ::placeholder {
    color: #FFFFFF;
    font-family: 'Roboto-Light';
}

.Input p {
    font-weight: 100;
    color: #742980;
    font-family: 'Roboto-Light';
    display: block;
}

.Input input, .Input textarea, .Input select {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    background-color: #742980;
    border-radius: 4px;
    border: 2px solid #742980;
    margin-top: 5px;
    color: #FFFFFF;
    font-family: 'Roboto-Light';
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 20px;
}

.invalid {
    background: #742980;
    border: 1px solid #e45454;
}

.Input span {
    color: #D9486E;
}