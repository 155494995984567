
.togBtn {
    border: none;
    background: none;
}

.imgBrand {
    height: 10vh;
}
.navi {
    position: fixed;
    z-index: 9999;
    max-width: 100vw;
}
.navi ul li img {
    height: 36px;
}
.navi ul li a {
    font-size: 14px;
}

.blue {
    color: #002c55;
}

.navbar-collapse {
    background: none;
}