::-webkit-scrollbar {
    width: 5px;
    height: 0px;
}

::-webkit-scrollbar-track {
    background-color: #17110D;
}

::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(138, 239, 243, 0.1);
    width: 5px;
    height: 0px;
    border-radius: 10px;
}

.iclogo {
    height: 2vw;
    width: auto;
    margin-bottom: 3vw;
}

/* ######################## SIZES ###############################*/

.screenMaxHeight100 {
    max-height: 100vh;
}

.screenMinHeight100 {
    min-height: 100vh;
}

.screenHeight100 {
    height: 100vh;
}

.screenMaxHeight80 {
    max-height: 80vh;
}

.screenHeight80 {
    height: 80vh;
}

.screenHeight60 {
    height: 60vh;
}

.screenHeight50 {
    height: 50vh;
}

.screenHeight40 {
    height: 40vh;
}

.screenHeight30 {
    height: 30vh;
}

.screenHeight20 {
    height: 20vh;
}

.screenHeight15 {
    height: 15vh;
}

.screenHeight10 {
    height: 10vh;
}

.screenHeight5 {
    height: 5vh;
}

.squareSize20 {
    height: 20px;
    width: auto;
}

/*########################### FONTS #########################*/

@font-face {
        font-family: 'Montserrat-Black';
        src: url('./fonts/Montserrat-Black.ttf') format("truetype");
}
.MontserratBlack {
    font-family: "Montserrat-Black";
}

@font-face {
        font-family: 'Montserrat-BlackItalic';
        src: url('./fonts/Montserrat-BlackItalic.ttf') format("truetype");
}
.MontserratBlackItalic {
    font-family: "Montserrat-BlackItalic";
}

@font-face {
        font-family: 'Montserrat-Bold';
        src: url('./fonts/Montserrat-Bold.ttf') format("truetype");
}
.MontserratBold {
    font-family: "Montserrat-Bold";
}

@font-face {
        font-family: 'Montserrat-BoldItalic';
        src: url('./fonts/Montserrat-BoldItalic.ttf') format("truetype");
}
.MontserratBoldItalic {
    font-family: "Montserrat-BoldItalic";
}

@font-face {
        font-family: 'Montserrat-ExtraBold';
        src: url('./fonts/Montserrat-ExtraBold.ttf') format("truetype");
}
.MontserratExtraBold {
    font-family: "Montserrat-ExtraBold";
    letter-spacing: 5px;
}

@font-face {
        font-family: 'Montserrat-ExtraBoldItalic';
        src: url('./fonts/Montserrat-ExtraBoldItalic.ttf') format("truetype");
}
.MontserratExtraBoldItalic {
    font-family: "Montserrat-ExtraBoldItalic";
}

@font-face {
        font-family: 'Montserrat-ExtraLight';
        src: url('./fonts/Montserrat-ExtraLight.ttf') format("truetype");
}
.MontserratExtraLight {
    font-family: "Montserrat-ExtraLight";
}

@font-face {
        font-family: 'Montserrat-ExtraLightItalic';
        src: url('./fonts/Montserrat-ExtraLightItalic.ttf') format("truetype");
}
.MontserratExtraLightItalic {
    font-family: "Montserrat-ExtraLightItalic";
}

@font-face {
        font-family: 'Montserrat-Italic';
        src: url('./fonts/Montserrat-Italic.ttf') format("truetype");
}
.MontserratItalic {
    font-family: "Montserrat-Italic";
}

@font-face {
        font-family: 'Montserrat-Light';
        src: url('./fonts/Montserrat-Light.ttf') format("truetype");
}
.MontserratLight {
    font-family: "Montserrat-Light";
}

@font-face {
        font-family: 'Montserrat-LightItalic';
        src: url('./fonts/Montserrat-LightItalic.ttf') format("truetype");
}
.MontserratLightItalic {
    font-family: "Montserrat-LightItalic";
}

@font-face {
        font-family: 'Montserrat-Medium';
        src: url('./fonts/Montserrat-Medium.ttf') format("truetype");
}
.MontserratMedium {
    font-family: "Montserrat-Medium";
}

@font-face {
        font-family: 'Montserrat-MediumItalic';
        src: url('./fonts/Montserrat-MediumItalic.ttf') format("truetype");
}
.MontserratMediumItalic {
    font-family: "Montserrat-MediumItalic";
}

@font-face {
        font-family: 'Montserrat-Regular';
        src: url('./fonts/Montserrat-Regular.ttf') format("truetype");
}
.MontserratRegular {
    font-family: "Montserrat-Regular";
}

@font-face {
        font-family: 'Montserrat-SemiBold';
        src: url('./fonts/Montserrat-SemiBold.ttf') format("truetype");
}
.MontserratSemiBold {
    font-family: "Montserrat-SemiBold";
}

@font-face {
        font-family: 'Montserrat-SemiBoldItalic';
        src: url('./fonts/Montserrat-SemiBoldItalic.ttf') format("truetype");
}
.MontserratSemiBoldItalic {
    font-family: "Montserrat-SemiBoldItalic";
}

@font-face {
        font-family: 'Montserrat-Thin';
        src: url('./fonts/Montserrat-Thin.ttf') format("truetype");
}
.MontserratThin {
    font-family: "Montserrat-Thin";
}

@font-face {
        font-family: 'Montserrat-ThinItalic';
        src: url('./fonts/Montserrat-ThinItalic.ttf') format("truetype");
}
.MontserratThinItalic {
    font-family: "Montserrat-ThinItalic";
}
@font-face {
        font-family: 'Montserrat-Black';
        src: url('./fonts/Montserrat-Black.ttf') format("truetype");
}
.MontserratBlack {
    font-family: "Montserrat-Black";
}

@font-face {
        font-family: 'Roboto-Black';
        src: url('./fonts/Roboto-Black.ttf') format("truetype");
}
.RobotoBlack {
    font-family: "Roboto-Black";
}

@font-face {
        font-family: 'Roboto-BlackItalic';
        src: url('./fonts/Roboto-BlackItalic.ttf') format("truetype");
}
.RobotoBlackItalic {
    font-family: "Roboto-BlackItalic";
}

@font-face {
        font-family: 'Roboto-Bold';
        src: url('./fonts/Roboto-Bold.ttf') format("truetype");
}
.RobotoBold {
    font-family: "Roboto-Bold";
}

@font-face {
        font-family: 'Roboto-BoldItalic';
        src: url('./fonts/Roboto-BoldItalic.ttf') format("truetype");
}
.RobotoBoldItalic {
    font-family: "Roboto-BoldItalic";
}

@font-face {
        font-family: 'Roboto-Italic';
        src: url('./fonts/Roboto-Italic.ttf') format("truetype");
}
.RobotoItalic {
    font-family: "Roboto-Italic";
}

@font-face {
        font-family: 'Roboto-Light';
        src: url('./fonts/Roboto-Light.ttf') format("truetype");
}
.RobotoLight {
    font-family: "Roboto-Light";
}

@font-face {
        font-family: 'Roboto-LightItalic';
        src: url('./fonts/Roboto-LightItalic.ttf') format("truetype");
}
.RobotoLightItalic {
    font-family: "Roboto-LightItalic";
}

@font-face {
        font-family: 'Roboto-Medium';
        src: url('./fonts/Roboto-Medium.ttf') format("truetype");
}
.RobotoMedium {
    font-family: "Roboto-Medium";
}

@font-face {
        font-family: 'Roboto-MediumItalic';
        src: url('./fonts/Roboto-MediumItalic.ttf') format("truetype");
}
.RobotoMediumItalic {
    font-family: "Roboto-MediumItalic";
}

@font-face {
        font-family: 'Roboto-Regular';
        src: url('./fonts/Roboto-Regular.ttf') format("truetype");
}
.Roboto {
    font-family: "Roboto-Regular";
}

@font-face {
        font-family: 'Roboto-Thin';
        src: url('./fonts/Roboto-Thin.ttf') format("truetype");
}
.RobotoThin {
    font-family: "Roboto-Thin";
}

@font-face {
        font-family: 'Roboto-ThinItalic';
        src: url('./fonts/Roboto-ThinItalic.ttf') format("truetype");
}
.RobotoThinItalic {
    font-family: "Roboto-ThinItalic";
}

@font-face {
        font-family: 'Poppins-Light';
        src: url('./fonts/Poppins-Light.ttf') format("truetype");
}
.PoppinsLight {
    font-family: "Poppins-Light";
}

.text-overflow-clamp {
    width: 100%;
    /* this code clamps based on specified lines */
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    display: -webkit-box;
}

.imgWidth {
    width: 100vw;
    height: auto;
}

/*---------------------------------------------------------------------------------------------------------------*/
/*########################################### BUTTONS ###########################################################*/
/*---------------------------------------------------------------------------------------------------------------*/

.smartBtn {
  border: none;
  border-radius: 10px;
  background: linear-gradient(to right,#77530a,#ffd277,#77530a,#77530a,#ffd277,#77530a);
  background-size: 250%;
  background-position: left;
  color: #ffd277;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition-duration: 1s;
  overflow: hidden;
}

.smartBtn::before {
  position: absolute;
  color: #ffd277;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 97%;
  height: 90%;
  border-radius: 8px;
  transition-duration: 1s;
  background-color: rgba(0, 0, 0, 0.842);
  background-size: 200%;
}

.smartBtn:hover {
  background-position: right;
  transition-duration: 1s;
}

.smartBtn:hover::before {
  background-position: right;
  transition-duration: 1s;
}

.smartBtn:active {
  transform: scale(0.95);
}

.button3 {
    border: solid #B34855;
    background-color: transparent;
    color: white;
    border-radius: 20px;
    padding: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
}

.signupBtn {
  position: relative;
  outline: none;
  text-decoration: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 45px;
  width: 130px;
  opacity: 1;
  background-color: #F2A71B;
  border: 1px solid rgba(192, 192, 192, 0.6);
}

.signupBtn .signupBtnSpan {
  color: #D96704;
  font-size: 16px;
  font-family: "Roboto-medium";
}

.signupBtn:hover {
  animation: rotate 0.7s ease-in-out both;
}

.signupBtn:hover .signupBtnSpan {
  animation: storm 0.7s ease-in-out both;
  animation-delay: 0.06s;
}

.signupBtn:disabled {
    background-color: #808080;
    opacity: 0.5;
}

.signupBtn:disabled .signupBtnSpan {
    color: #3D3D3D;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
  25% {
    transform: rotate(3deg) translate3d(0, 0, 0);
  }
  50% {
    transform: rotate(-3deg) translate3d(0, 0, 0);
  }
  75% {
    transform: rotate(1deg) translate3d(0, 0, 0);
  }
  100% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
}

@keyframes storm {
  0% {
    transform: translate3d(0, 0, 0) translateZ(0);
  }
  25% {
    transform: translate3d(4px, 0, 0) translateZ(0);
  }
  50% {
    transform: translate3d(-3px, 0, 0) translateZ(0);
  }
  75% {
    transform: translate3d(2px, 0, 0) translateZ(0);
  }
  100% {
    transform: translate3d(0, 0, 0) translateZ(0);
  }
}

/*---------------------------------------------------------------------------------------------------------------*/
/*########################################### COLORS ############################################################*/
/*---------------------------------------------------------------------------------------------------------------*/

.blue {
    color: #208bff;
}

.orange {
    color: #D96704;
}

.purple {
    color: #742980;
}

.or {
    color: #BF8B4B;
}

.cream {
    color: #F2DFBB;
}

/*---------------------------------------------------------------------------------------------------------------*/
/*########################################### TITLE ANIMATION ###################################################*/
/*---------------------------------------------------------------------------------------------------------------*/

.ml1 {
  font-weight: 900;
  font-size: 10vw;
  font-family: 'Montserrat-Black';
  color: #BF8B4B;
}

.ml1 .letters {

}

.ml1 .letter {
  display: inline-block;
  line-height: 1em;
  /*filter: blur(.5px);*/
  /*-webkit-filter: blur(.5px);*/
  /*background: url("https://myd3s-static.s3.ap-southeast-2.amazonaws.com/photo/5_elements_3.jpg");
  background-size: cover;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;*/
}

.ml1 .text-wrapper {
  position: relative;
  display: inline-block;
  padding-top: 0.1em;
  padding-right: 0.05em;
  padding-bottom: 0.15em;
}

.ml1 .line {
  opacity: 0;
  position: absolute;
  left: 0;
  height: 3px;
  width: 100%;
  background-color: #BF8B4B;
  transform-origin: 0 0;
}

.ml1 .line1 { top: 0; }
.ml1 .line2 { bottom: 0; }

/*---------------------------------------------------------------------------------------------------------------*/
/*########################################### CARDS' CONTAINERS #################################################*/
/*---------------------------------------------------------------------------------------------------------------*/

.training-container {
	position: relative;
	height: 60vh;
	background-color: none;
	width: 100%;
	margin:auto;
	/*transform: translateX(-50%)*/
}
.training-items{
	position: relative;
	left: 0%;
	text-align: center;
	width: auto;
	height: 55vh;
	display: flex;
    justify-content: space-between;
    overflow-x: auto;
	overflow-y: hidden;
    /*animation: slide 50s linear infinite alternate;*/
}

@keyframes slide {
  0% {
    transform: translateX(0%);
  }
  50%{
    transform: translateX(-80%);
  }
  100% {
    transform: translateX(0%);
  }
}

.training-items:hover {
    animation-play-state: paused;
}

.tools-items{
	position: relative;
	left: 0%;
	text-align: center;
	width: auto;
	height: 55vh;
	display: flex;
    justify-content: space-between;
    overflow-x: auto;
    overflow-y: hidden;
    /*animation: slide 50s linear infinite alternate;*/
}

.tools-items .toolCard:hover {
    animation-play-state: paused;
}

.partners-container {
	position: relative;
	height: 50vw;
	background: none;
	width: 100vw;
	overflow-x: auto;
    overflow-y: hidden;
}
.partners-items{
	position: relative;
	left: 0%;
	text-align: center;
	width: auto;
	height: 55vh;
	display: flex;
    justify-content: space-between;
    animation: slide 120s linear infinite alternate;
}

.training-container::before,
.training-container::after,
.training-container__prev,
.training-container__next {
  position: absolute;
  top: 25vh;
  width: 4rem;
  height: 4rem;
  transform: translateY(-50%);
  border-radius: 50%;
  font-size: 0;
  outline: 0;
  background-color: #742980;
}

.training-container::before,
.training-container__prev {
  left: 10px;
}

.training-container::after,
.training-container__next {
  right: 10px;
}

.training-container::before,
.training-container::after {
  content: '';
  z-index: 1;
  background-color: none;
  background-size: 2rem 2rem;
  background-repeat: no-repeat;
  background-position: center center;
  color: #fff;
  font-size: 2.5rem;
  line-height: 4rem;
  text-align: center;
  pointer-events: none;
}

.training-container::before {
  background-image: url('https://myd3s-static.s3.ap-southeast-2.amazonaws.com/left-arrow.png');
}

.training-container::after {
  background-image: url('https://myd3s-static.s3.ap-southeast-2.amazonaws.com/right-arrow.png');
}

/*---------------------------------------------------------------------------------------------------------------*/
/*########################################### CARDS #############################################################*/
/*---------------------------------------------------------------------------------------------------------------*/

.cardShad {
    background-color: #F5F5F5;
    border: 1px solid;
    border-radius: 0.5rem;
    border-color: #d74cf6;
    border-width: 2px;
    box-shadow: rgba(215, 76, 246, 0.3) 8px 5px 5px;
}

.cardOr {
    background-color: #BF8B4B;
    border: 1px solid;
    border-radius: 0.5rem;
    border-color: #BF8B4B;
    border-width: 2px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 20px;
}

.cardGold {
    background-color:;
    border: 1px solid;
    border-radius: 0.5rem;
    border-color: #BF8B4B;
    border-width: 2px;
}

.cardPurple {
    background-color: #742980;
    border: 1px solid;
    border-radius: 0.5rem;
    border-color: #742980;
    border-width: 2px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 20px;
}

.cardCream {
    background-color: #F2DFBB;
    opacity: 0.8;
    border: 1px solid;
    border-radius: 0.5rem;
    border-color: #F2DFBB;
    border-width: 1px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 5px;
}
.smartCard {
  border: none;
  border-radius: 10px;
  background: linear-gradient(to right,#77530a,#ffd277,#77530a,#77530a,#ffd277,#77530a);
  background-size: 250%;
  background-position: left;
  color: #ffd277;
  position: relative;
  display: flex;
  cursor: pointer;
  transition-duration: 1s;
  overflow: hidden;
  font-family: "Montserrat-Black";
}

.smartCard::before {
  position: absolute;
  color: #ffd277;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 97%;
  height: 90%;
  border-radius: 8px;
  transition-duration: 1s;
  background-color: rgba(0, 0, 0, 0.842);
  background-size: 200%;
}

.smartCard:hover {
  background-position: right;
  transition-duration: 1s;
}

.smartCard:hover::before {
  background-position: right;
  transition-duration: 1s;
}

.smartCard:active {
  transform: scale(0.95);
}

.trainingCard {
    padding: 1vw;
    opacity: 1;
    /*border: none;*/
    /*border-radius: 10px;*/
    border-radius: .5rem 2rem;
    position: relative;
    vertical-align: middle;
    display: inline-block;
    list-style: none;
    width: 25vw;
    height: 45vh;
    /*background-color: rgba(191, 139, 75, 0.5);*/
    background: linear-gradient(90deg,#621176,#81189b,#581169);
    transition-duration: .5s;
    overflow: hidden;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.7) 2px 2px 8px;

}
.trainingCard-img {
    width: 100%;
    height: auto;
    border-radius: .5rem 2rem;
    border: 1px solid;
}

.toolCard {
    padding: 1vw;
    opacity: 1;
    border-radius: .5rem 2rem;
    position: relative;
    position: relative;
    vertical-align: middle;
    display: inline-block;
    list-style: none;
    width: 25vw;
    height: 45vh;
    /*background-color: rgba(191, 139, 75, 0.5);*/
    background: linear-gradient(90deg,#621176,#81189b,#581169);
    transition-duration: .5s;
    overflow: hidden;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.7) 2px 2px 8px;
    transition-duration: .5s;
    overflow: hidden;
    cursor: pointer;
    /*box-shadow: rgba(0, 0, 0, 0.7) 2px 2px 8px;*/
}

.partnersCard {
    padding: 0px;
    opacity: 1;
    border: none;
    border-radius: 10px;
    position: relative;
    vertical-align: middle;
    display: inline-block;
    list-style: none;
    width: 20vw;
    height: 20vw;
    background-color: rgba(191, 139, 75, 0);
    /*background-color: #FFF;*/
    transition-duration: .5s;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.7) 2px 2px 8px;

}

.partners-img {
    width: 100%;
    height: 100%;
}

.portraitCard {
    padding: 8px;
    opacity: 1;
    border-radius: .5rem 2rem;
    position: relative;
    vertical-align: middle;
    display: inline-block;
    list-style: none;
    width: 25vw;
    height: 50vh;
    /*background-color: rgba(191, 139, 75, 0.5);*/
    background: linear-gradient(90deg,#621176,#81189b,#581169);
    transition-duration: .5s;
    overflow: hidden;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.7) 2px 2px 8px;
    transition-duration: .5s;
    overflow: hidden;
    cursor: pointer;
    /*animation: slide 15s linear infinite alternate;*/
}

.card-text {
    color: #FFF;
}

/*---------------------------------------------------------------------------------------------------------------*/
/*########################################### MODAL & IFRAME ####################################################*/
/*---------------------------------------------------------------------------------------------------------------*/

.googleIframe {
    height: 50vh;
    width: 50vw;
    border: 10px solid;
    border-color: #FFF;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 5px;
}

.custom-modal {
    z-index: 1500;
    display: none;
    position: absolute;
}
.modal{
    z-index: 9999;
}

.modal-dialog {
    border-radius: 20px;
    padding: 5px;
}

.modal-content {
    /*min-height: 80vh;*/
    /*background-color: #F2DFBB;
    border-color: #F2DFBB;
    border-width: 1px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 5px;*/
    border-radius: 20px;
    padding: 5px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    background: linear-gradient(90deg,#621176,#81189b,#581169);
}

.innerHTML h2 {
    color: #BF8B4B;
    font-family: "Roboto-BoldItalic"
}

.innerHTML ul li {
    margin-top: 2vh;
}

/*---------------------------------------------------------------------------------------------------------------*/
/*########################################### CALENDAR ##########################################################*/
/*---------------------------------------------------------------------------------------------------------------*/

.react-calendar {
  width: 80vw;
  max-width: 100%;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  background: linear-gradient(90deg,#621176,#81189b,#581169);
  border-radius: 20px;
  border: 2px solid #ffd277;
  font-family: "Roboto-Bold";
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  border-radius: 20px;
  font-size: 1.5em;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #ffd277;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1em;
  color: #FFF;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: #ffff76;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #ffd277;
  border-radius: 20px
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #ffd277;
  color: white;
  border-radius: 20px;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
/*---------------------------------------------------------------------------------------------------------------*/
/*########################################### FORM ##############################################################*/
/*---------------------------------------------------------------------------------------------------------------*/

.inputFile {
    width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputFile + label{
    position: relative;
    outline: none;
    text-decoration: none;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 45px;
    width: 130px;
    opacity: 1;
    background-color: #002c55;
    border: 1px solid rgba(0, 0, 0, 0.6);
    color: #ffffff;
    font-size: 16px;
}

/*---------------------------------------------------------------------------------------------------------------*/
/*########################################### CONTENTS ##########################################################*/
/*---------------------------------------------------------------------------------------------------------------*/


.d3s_header {
    max-height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    z-index: -1;
    position: absolute;
    border-radius: 20px;
    padding: 5px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    background-image: linear-gradient(144deg,#AF40FF, #5B42F3 50%,#00DDEB);
}

.classroom {
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 20px;
    border: 10px solid;
    border-color: #FFF;
    border-radius: 10px;
    width: auto;
}

.waiting-room {
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 20px;
    border: 10px solid;
    border-color: #FFF;
    border-radius: 10px;
    width: auto;
}

.peoples {
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 20px;
}

.mongolia {
    z-index: 1500;
}

.welcome {
    width: 100vw;
    background-image: url('https://myd3s-static.s3.ap-southeast-2.amazonaws.com/Illustrated.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.logout {
    width: 60px;
    height: auto;
}

.logo {
    width: 300px;
    height: auto;
}

.back1 {
    background-image: url('./picts/1.png');
    background-size: 100vw;
    background-repeat: repeat-y;
}

.back2 {
    background-image: url('./picts/2.png');
    background-size: 100vw;
    background-repeat: repeat-y;
}

.back3 {
    background-image: url('./picts/3.png');
    background-size: 100vw;
    background-repeat: repeat-y;
}

.homeIcon {
    width: 10vw;
}

.logoD3S {
    animation: logRot 2s linear;

}

@keyframes logRot {
  0% {
    transform: rotate(0deg) rotate3d(0, 0, 0, 0deg) scale3d(0.5, 0.5, 0.5);;
  }
  25% {
    transform: rotate(180deg) rotate3d(0, 1, 0, 90deg) scale3d(1.3, 1.3, 1.3);;
  }
  50% {
    transform: rotate(360deg) rotate3d(0, 1, 0, 0deg) scale3d(1.6, 1.6, 1.6);;
  }
  75% {
    transform: rotate(180deg) rotate3d(1, 0, 0, 270deg) scale3d(1.3, 1.3, 1.3);;
  }
  100% {
    transform: rotate(0deg) rotate3d(1, 0, 0, 360deg) scale3d(0.5, 0.5, 0.5);;
  }
}

.navBrand {
    animation: brandRound 10s linear infinite;
}

@keyframes brandRound {
  0% {
    transform: rotate(0deg)  scale3d(1, 1, 1);;
  }
  25% {
    transform: rotate(20deg) scale3d(1.1, 1.1, 1.1);;
  }
  50% {
    transform: rotate(0deg) scale3d(1, 1, 1);;
  }
  75% {
    transform: rotate(-20deg) scale3d(1.1, 1.1, 1.1);;
  }
  100% {
    transform: rotate(0deg) scale3d(1, 1, 1);;
  }
}



/*---------------------------------------------------------------------------------------------------------------*/
/*########################################### MEDIA QUERIES #####################################################*/
/*---------------------------------------------------------------------------------------------------------------*/



/*---------------------------------------------------------------------------------------------------------------*/
/*                               (min-width: 290px) and (max-width: 499px)                                       */
/*---------------------------------------------------------------------------------------------------------------*/

@media (min-width: 290px) and (max-width: 499px) {

    .homeIcon {
        width: 10vw;
    }
    .header_container {
        margin-top: 5vw;
    }
    .card-title {
        font-size: 5vw;
    }
    .training-container {
        height: 100vw;
    }
    .training-container::before,
    .training-container::after,
    .training-container__prev,
    .training-container__next {
      position: relative;
      top: 0vh;
      width: 0rem;
      height: 0rem;
      transform: translateY(0%);
      border-radius: 0%;
      font-size: 0;
      outline: 0;
      background-color: none;
      opacity: 0;
    }
    .training-items {
        padding-left: 1vw;
        height: 95vw;
    }
    .trainingCard {
        width: 90vw;
        height: 80vw;
    }
    .presentation-row {
        height: 120vw;
        margin-bottom: 40vw;
    }
    .connectButton {
        margin-top: 5vw;
        padding-top: 2vw;
    }
    .connectLink {
        font-size: 5vw;
    }
    .baseSection {
        margin-top: 15vw;
    }
    .classroom {
        height: 100vw;
        width: auto;
    }
    .tools-items {
        padding-left: 1vw;
        height: 100vw;
        margin-bottom: 5vw;
    }
    .toolCard {
        width: 90vw;
        height: 80vw;
    }
    .partnersTitle {
        margin-top: 15vw;
    }
    .partners-container {
        height: 100vw;
        margin-top: 10vw;
    }
    .partners-items {
        height: 100vw;
    }
    .partnersCard {
        width: 70vw;
        height: 70vw;
    }
    .portraitContainer {
        margin-top: 0vw;
        padding-top: 0;
    }
    .portraitCard {
        width: 90vw;
        height: 100vw;
        margin-left: -3vw;
    }
    .googleIframe {
        height: 50vh;
        width: 80vw;
    }
    .spEmail {
        font-size: 15px;
    }
    .iclogo {
        height: 10vw;
    }
}

/*---------------------------------------------------------------------------------------------------------------*/
/*                               (min-width: 500px) and (max-width: 799px)                                       */
/*---------------------------------------------------------------------------------------------------------------*/

@media (min-width: 500px) and (max-width: 799px) {
    .homeIcon {
        width: 10vw;
    }
    .header_container {
        margin-top: 30vw;
    }
    .card-title {
        font-size: 4vw;
    }
    .training-container {
        height: 80vw;
    }
    .training-container::before,
    .training-container::after,
    .training-container__prev,
    .training-container__next {
      position: relative;
      top: 0vh;
      width: 0rem;
      height: 0rem;
      transform: translateY(0%);
      border-radius: 0%;
      font-size: 0;
      outline: 0;
      background-color: none;
      opacity: 0;
    }
    .training-items {
        padding-left: 3vw;
        height: 80vw;
    }
    .trainingCard {
        width: 80vw;
        height: 70vw;
    }
    .connectButton {
        margin-top: 30vw;
    }
    .presentation-row {
        height: 100vw;
        margin-bottom: 20vw;
    }
    .connectLink {
        font-size: 6vw;
    }
    .baseSection {
        margin-top: 15vw;
    }
    .classroom {
        height: 100vw;
        width: auto;
    }
    .tools-items {
        padding-left: 3vw;
        height: 100vw;
    }
    .toolCard {
        width: 80vw;
        height: 70vw;
    }
    .partnersTitle {
        margin-top: 15vw;
    }
    .partners-container {
        height: 70vw;
        margin-top: 15vw;
    }
    .partners-items {
        height: 70vw;
    }
    .partnersCard {
        width: 60vw;
        height: 60vw;
    }
    .portraitContainer{
        margin-top: 15vw;
    }
    .portraitCard {
        width: 70vw;
        margin-left: 0vw;
        height: 80vw;
    }
    .googleIframe {
        height: 50vh;
        width: 80vw;
    }
    .spEmail {
        font-size: 4vw;
    }
    .iclogo {
        height: 5vw;
    }
}

/*---------------------------------------------------------------------------------------------------------------*/
/*                               (min-width: 800px) and (max-width: 1099px)                                      */
/*---------------------------------------------------------------------------------------------------------------*/

@media (min-width: 800px) and (max-width: 1099px) {
    .homeIcon {
        width: 10vw;
    }
    .header_container {
        margin-top: 30vw;
    }
    .section-title {
        font-size: 5vw;
        margin-bottom: 4vw;
    }
    .card-title {
        font-size: 2.5vw;
    }
    .training-container {
        height: 40vw;
    }
    .training-items {
        padding-left: 5vw;
        height: 40vw;
    }
    .trainingCard {
        width: 40vw;
        height: 30vw;
    }
    .training-container::before,
    .training-container::after,
    .training-container__prev,
    .training-container__next {
      position: absolute;
      top: 15vh;
    }
    .presentation-row {
        margin-top: 0vw;
    }
    .connectButton {
        margin-top: 10vw;
    }
    .connectLink {
        font-size: 5vw;
    }
    .classroom {
        height: 100vw;
        width: auto;
    }
    .baseSection {
        margin-top: 10vw;
    }
    .tools-items {
        padding-left: 5vw;
        height: 40vw;
    }
    .toolCard {
        width: 40vw;
        height: 30vw;
    }
    .partnersTitle {
        margin-top: 15vw;
    }
    .partners-container {
        height: 30vw;
        margin-top: 10vw;
    }
    .partners-items {
        height: 25vw;
    }
    .partnersCard {
        width: 25vw;
        height: 25vw;
    }
    .portraitContainer{
        margin-top: 5vw;
    }
    .portraitCard {
        width: 60vw;
        margin-left: 4vw;
        height: 70vw;
    }
    .googleIframe {
        height: 50vh;
        width: 80vw;
    }
    .spEmail {
        font-size: 25px;
    }
    .iclogo {
        height: 5vw;
    }
}

/*---------------------------------------------------------------------------------------------------------------*/
/*                               (min-width: 1100px) and (max-width: 1399px)                                     */
/*---------------------------------------------------------------------------------------------------------------*/

@media (min-width: 1100px) and (max-width: 1399px) {
    .homeIcon {
        width: 10vw;
    }
    .header_container {
        margin-top: 35vw;
    }
    .section-title {
        font-size: 5vw;
        margin-bottom: 4vw;
    }
    .card-title {
        font-size: 2vw;
    }
    .training-items {
        padding-left: 5vw;
        height: 50vw;
    }
    .trainingCard {
        width: 40vw;
        height: 30vw;
    }
    .presentation-row {
        margin-top: 5vw;
    }
    .connectButton {
        margin-top: 5vw;
    }
    .connectLink {
        font-size: 3vw;
    }
    .baseSection {
        margin-top: 10vw;
    }
    .tools-items {
        padding-left: 5vw;
        height: 60vw;
    }
    .toolCard {
        width: 40vw;
        height: 30vw;
    }
    .partnersTitle {
        margin-top: 5vw;
    }
    .partners-container {
        height: 40vw;
        margin-top: 10vw;
        padding-top: 0;
    }
    .partners-items {
        height: 40vw;
        margin-top: 0;
    }
    .partnersCard {
        width: 30vw;
        height: 30vw;
    }
    .portraitContainer{
        margin-top: 5vw;
    }
    .portraitCard {
        width: 50vw;
        margin-left: -5vw;
        height: 55vw;
    }
    .googleIframe {
        height: 60vh;
        width: 80vw;
    }
    .spEmail {
        font-size: 25px;
    }
}

/*---------------------------------------------------------------------------------------------------------------*/
/*                               (min-width: 1400px) and (max-width: 1699px)                                     */
/*---------------------------------------------------------------------------------------------------------------*/

@media (min-width: 1400px) and (max-width: 1699px) {
    .homeIcon {
        width: 10vw;
    }
    .header_container {
        margin-top: 30vw;
    }
    .section-title {
        font-size: 5vw;
        margin-bottom: 4vw;
    }
    .card-title {
        font-size: 2vw;
    }
    .training-items {
        padding-left: 5vw;
        height: 90vw;
    }
    .trainingCard {
        width: 40vw;
        height: 30vw;
    }
    .presentation-row {
        margin-top: 5vw;
    }
    .connectButton {
        margin-top: 5vw;
        margin-bottom: 5vw;
        height: 5vw;
    }
    .connectLink {
        font-size: 3vw;
    }
    .baseSection {
        margin-top: 15vw;
    }
    .tools-items {
        padding-left: 5vw;
        height: 90vw;
    }
    .toolCard {
        width: 40vw;
        height: 30vw;
    }
    .partners-container {
        margin-top: 5vw;
        padding-top: 0;
        height: 30vw;
    }
    .partners-items {
        height: auto;
    }
    .partnersCard {
        width: 25vw;
        height: 25vw;
    }
    .portraitContainer{
        margin-top: 5vw;
    }
    .portraitCard {
        width: 40vw;
        margin-left: -5vw;
        height: 45vw;
    }
    .googleIframe {
        height: 60vh;
        width: 80vw;
    }
    .spEmail {
        font-size: 25px;
    }
}

/*---------------------------------------------------------------------------------------------------------------*/
/*                               (min-width: 1700px) and (max-width: 1999px)                                     */
/*---------------------------------------------------------------------------------------------------------------*/

@media (min-width: 1700px) and (max-width: 1999px) {
    .homeIcon {
        width: 10vw;
    }
    .header_container {
        margin-top: 30vw;
    }
    .section-title {
        font-size: 5vw;
        margin-bottom: 4vw;
    }
    .card-title {
        font-size: 1.5vw;
    }
    .training-items {
        padding-left: 5vw;
        height: 90vw;
    }
    .trainingCard {
        width: 40vw;
        height: 30vw;
    }
    .presentation-row {
        margin-top: 5vw;
    }
    .connectButton {
        margin-top: 10vw;
    }
    .connectLink {
        font-size: 3vw;
    }
    .baseSection {
        margin-top: 10vw;
    }
    .tools-items {
        padding-left: 5vw;
        height: 90vw;
    }
    .toolCard {
        width: 40vw;
        height: 30vw;
    }
    .partnersTitle {
        margin-top: 5vw
    }
    .partners-container {
        height: 30vw;
        margin-top: 5vw;
    }
    .partners-items {
        height: 30vw;
    }
    .partnersCard {
        width: 25vw;
        height: 25vw;
    }
    .portraitContainer{
        margin-top: 0vw;
    }
    .portraitCard {
        width: 35vw;
        margin-left: 1vw;
        height: 40vw;
    }
    .googleIframe {
        height: 60vh;
        width: 80vw;
    }
    .spEmail {
        font-size: 25px;
    }
}

/*---------------------------------------------------------------------------------------------------------------*/
/*                               (min-width: 2000px) and (max-width: 2299px)                                     */
/*---------------------------------------------------------------------------------------------------------------*/

@media (min-width: 2000px) and (max-width: 2299px) {
    .homeIcon {
        width: 10vw;
    }
    .header_container {
        margin-top: 35vw;
    }
    .section-title {
        font-size: 5vw;
        margin-bottom: 4vw;
    }
    .card-title {
        font-size: 2vw;
    }
    .training-items {
        padding-left: 5vw;
        height: 50vw;
    }
    .trainingCard {
        width: 40vw;
        height: 30vw;
    }
    .presentation-row {
        margin-top: 10vw;
    }
    .connectButton {
        margin-top: 5vw;
    }
    .connectLink {
        font-size: 3vw;
    }
    .baseSection {
        margin-top: 10vw;
    }
    .tools-items {
        padding-left: 5vw;
        height: 50vw;
    }
    .toolCard {
        width: 40vw;
        height: 30vw;
    }
    .partnersTitle {
        margin-top: 5vw
    }
    .partners-container {
        height: 30vw;
        margin-top: 5vw;
    }
    .partners-items {
        height: 30vw;
    }
    .partnersCard {
        width: 25vw;
        height: 25vw;
    }
    .portraitContainer{
        margin-top: 3vw;
    }
    .portraitCard {
        width: 30vw;
        margin-left: 1vw;
        height: 35vw;
    }
    .googleIframe {
        height: 60vh;
        width: 80vw;
    }
    .spEmail {
        font-size: 25px;
    }
}

/*---------------------------------------------------------------------------------------------------------------*/
/*                               (min-width: 2300px) and (max-width: 2599px)                                     */
/*---------------------------------------------------------------------------------------------------------------*/

@media (min-width: 2300px) {
    .homeIcon {
        width: 10vw;
    }
    .header_container {
        margin-top: 35vw;
    }
    .section-title {
        font-size: 5vw;
        margin-bottom: 4vw;
    }
    .card-title {
        font-size: 2vw;
    }
    .training-items {
        padding-left: 5vw;
        height: 50vw;
    }
    .trainingCard {
        width: 40vw;
        height: 30vw;
    }
    .presentation-row {
        margin-top: 5vw;
    }
    .connectButton {
        margin-top: 5vw;
    }
    .connectLink {
        font-size: 3vw;
    }
    .baseSection {
        margin-top: 10vw;
    }
    .tools-items {
        padding-left: 5vw;
        height: 50vw;
    }
    .toolCard {
        width: 40vw;
        height: 30vw;
    }
    .partners-container {
        height: 30vw;
        margin-top: 5vw;
    }
    .partners-items {
        height: 30vw;
    }
    .partnersCard {
        width: 20vw;
        height: 20vw;
    }
    .portraitContainer{
        margin-top: 00vw;
    }
    .portraitCard {
        width: 30vw;
        margin-left: 1vw;
        height: 35vw;
    }
    .googleIframe {
        height: 60vh;
        width: 80vw;
    }
    .spEmail {
        font-size: 35px;
    }
}

